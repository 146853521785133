<template>
  <div class="preview">
    <!--  HEADER  -->
    <div class="zq-page-title-wrapper d-flex">
      <h3 class="zq-page-title">{{ texts.previewPage.title }}</h3>
      <IconWithTooltip class="zq--header-tooltip" :text="descriptions.pageTitle"/>
    </div>
    <CTabs class="zq--tabs" @update:activeTab="updateActiveTab" ref="instantWinsTabs">
      <CTab :title="texts.previewPage.instantWinTab">
        <InstantWinDetails v-if="isInstantWin"  />
      </CTab>
      <CTab :title="texts.previewPage.entrantsTab">
        <EntrantsDetails v-if="isEntrants" :entityId="$route.params.id" :entityType="'InstantWin'"/>
      </CTab>
      <CTab :title="texts.previewPage.awardsTab">
        <AwardsDetails v-if="isAwards" :entityType="'InstantWin'" />
      </CTab>
    </CTabs>
  </div>
</template>

<script>
import InstantWinDetails from './InstantWinDetails';
import IconWithTooltip from '@/shared/UI/IconWithTooltip.vue';
import AwardsDetails from '@/views/ziqni/achievements/AwardsDetails.vue';
import EntrantsDetails from '@/shared/components/supportModels/entrants/EntityEntrants.vue';
import { instantWinsTexts } from '@/config/pageTexts/instantWins.json';
import { instantWins } from '@/config/descriptions/instantWins.json';

export default {
  name: 'PreviewInstantWin' ,
  components: { EntrantsDetails, AwardsDetails, IconWithTooltip, InstantWinDetails },
  data() {
    return {
      descriptions: {
        ...instantWins.preview
      },
      texts: {
        ...instantWinsTexts
      },
      isInstantWin: false,
      isEntrants: false,
      isAwards: false,
      tabsMap: {
        0: 'isInstantWin',
        1: 'isEntrants',
        2: 'isAwards',
      },
    }
  },
  mounted() {
    this.isInstantWin = true;
  },
  methods: {
    updateActiveTab(val) {
      switch (this.tabsMap[val]) {
        case 'isInstantWin':
          this.isInstantWin = true;
          break;
        case 'isEntrants':
          this.isEntrants = true;
          break;
        case 'isAwards':
          this.isAwards = true;
          break;
      }
    },
  }
};
</script>

<style scoped lang="scss">

</style>
